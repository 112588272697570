import QuickChart from './charts';
import { fetchBiasData } from './fetch-bias-data';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import * as dayjs from 'dayjs';

const ENABLE_FAKE_DATA_TEST = false;

Chart.register(annotationPlugin);

class ChartData {
  constructor (data, times, timeLabel) {
    this.data = data;
    this.times = times;
    this.timeLabel = timeLabel;
  }
}

function getClickListeners (element) {
  // eslint-disable-next-line no-underscore-dangle
  return (element && element._events && element._events.click) || false;
}

function getDataLabels (range) {
  let timeLabel;
  let dataLabel;

  switch (range.toUpperCase()) {
    case '1W':
      dataLabel = 'summary_date'; // Note this is different from the other cases.
      timeLabel = 'Bias';
      break;
    case '1M':
      dataLabel = 'record_date';
      timeLabel = 'Bias';
      break;
    case '3M':
      dataLabel = 'record_date'; // @TODO maybe this is what will be used?
      timeLabel = 'Bias';
      break;
    case '1Y':
      dataLabel = 'record_date'; // @TODO maybe this is what will be used?
      timeLabel = 'Bias';
      break;
    case 'ALL':
    default:
      dataLabel = 'record_date'; // @TODO maybe this is what will be used?
      timeLabel = 'Bias';
      break;
  }

  return [
    dataLabel,
    timeLabel
  ];
}

function convertDataToChartFormat (range, _data) {
  const [dataLabel, timeLabel] = getDataLabels(range);
  const data = [];
  const times = [];
  const dataDict = {};

  _data.forEach(event => {
    const time = event[dataLabel];
    if (time in dataDict) {
      console.error('Duplicate time found:', time);
      console.error('event:', event);
    }
    dataDict[time] = Math.round(event.bias_score * 1000) / 1000;
  });

  Object.keys(dataDict).sort().forEach(key => {
    const val = dataDict[key];
    times.push(key);
    data.push(val);
  });

  // Useful for testing.
  if (ENABLE_FAKE_DATA_TEST) {
    // Adds some samples in the conservative axes, which is needs special
    // consideration because it's not generated naturally from the data.
    _data.forEach(event => {
      let time = event[dataLabel];
      time += 100000;
      if (times.indexOf(time) !== -1) {
        // hr_numbers can have duplicates.  This is fine.
        if (dataLabel !== 'hr_number') {
          /* eslint-disable-next-line no-debugger */
          debugger;
          console.error('Duplicate time found: ' + time);
        }
        return;
      }
      times.push(time);
      // round bias score to two decimal places
      event.bias_score = Math.round(event.bias_score * 1000) / 1000;
      data.push(0.5);
    });
  }

  return new ChartData(data, times, timeLabel);
}

function getGraphType (range) {
  switch (range.toUpperCase()) {
    case '1W':
      return 'weekly_bias_graph';
    case '1M':
      return 'monthly_bias_graph';
    case '3M':
      return 'quarterly_bias_graph';
    case 'ALL':
      return 'alltime_bias_graph';
    case '1Y':
    default:
      return 'annual_bias_graph';
  }
}

function createBiasGraphChartOptions (chartData, ymin = -100, ymax = 100, stepSize = 25) {
  return {
    scales: {
      y: { // Updated to 'y' for version 4
        beginAtZero: true,
        title: {
          display: true,
          text: '   Liberal       Conservative',
          font: {
            size: 12,
            weight: 'bold'
          }
        },
        min: ymin, // Directly under 'y'
        max: ymax, // Directly under 'y'
        ticks: {
          stepSize // Directly under 'ticks'
        }
      },
      x: { // Updated to 'x' for version 4
        title: {
          display: true,
          text: 'DATE',
          /* small size for mobile */
          font: {
            size: 12,
            weight: 'bold'
          }
        },
        ticks: {
          display: true, // Directly under 'x'
          autoskip: true,
          /* eslint-disable-next-line */
          callback: function (value, index) {
            let label = chartData.times[index] + '';
            const isIsoDate = label.includes('T');
            if (isIsoDate) {
              label = label.split('T')[0];
            }
            label = label.split('-').slice(1).join('-');
            return label;
          }
        }
      }
    },
    responsive: true,
    plugins: {
      legend: { // Moved under 'plugins'
        display: false
      },
      title: { // Moved under 'plugins'
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          /* eslint-disable-next-line func-names */
          label: function (context) {
            const value = context.dataset.data[context.dataIndex];
            // const [, biasDescption] = getBiasInfo(value);
            // return [`Bias: ${value}`, '(' + biasDescption + ')'];
            return [`Bias: ${value}`];
          },
          /* eslint-disable-next-line func-names */
          title: function (context) {
            let date = '' + context[0].label;
            const isTimeStamp = date.includes('T');
            if (isTimeStamp) {
              if (!date.includes('Z')) {
                date = date + 'Z';
              }
              const time = dayjs(date).format('h:mm A').replace(/^0+/, '');
              const formattedDate = dayjs(date).format('MMM D, ') + time.toLowerCase();
              return formattedDate;
            } else {
              const year = date.split('-')[0];
              const month = date.split('-')[1];
              const day = date.split('-')[2];
              const prettyDate = new Date(year, month - 1, day)
                .toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
              return prettyDate;
            }
          }
          // Add more customizations if needed
        }
        // Additional styling options for the tooltip
      },
      annotation: {
        annotations: {
          lineAtZero: { // This is the annotation id (can be any string)
            type: 'line',
            yMin: 0, // Start at y=0
            yMax: 0, // End at y=0
            borderColor: 'rgba(0, 0, 0, 0.6)', // Line color
            borderWidth: 2 // Line width
          }
        }
      }
    }
    // other chart options...
  };
}

function initializeDisplay (disabledSelector, enabledOverride) {
  const enabled = enabledOverride;
  if (enabled === false) {
    return false;
  }
  const disabled = document.querySelectorAll(disabledSelector);
  disabled.forEach($div => {
    $div.style.removeProperty('display');
    $div.classList.remove('chartDisabledOverlay');
  });
  return true;
}

function createLinearGradient (ctx, top, bottom) {
  const gradient = ctx.createLinearGradient(0, top, 0, bottom);
  gradient.addColorStop(0.85, 'rgba(0, 0, 255, 0.7)'); // Blue at -1.0
  gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.0)'); // Transparent White at 0.0
  gradient.addColorStop(0.15, 'rgba(255, 0, 0, 0.7)'); // Red at 1.0
  return gradient;
}

function generateGradient (ctx, chartArea) {
  const height = chartArea.height;
  const gradient = createLinearGradient(ctx, chartArea.top, chartArea.top + height);
  gradient.addColorStop(0.85, 'rgba(0, 0, 255, 0.7)'); // Blue at -1.0
  gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.0)'); // Transparent White at 0.0
  gradient.addColorStop(0.15, 'rgba(255, 0, 0, 0.7)'); // Red at 1.0
  return gradient;
}

async function updateData (chart, range, data) {
  const chartData = convertDataToChartFormat(range, data);

  // Determine ymin and ymax based on data
  let ymin = Math.min(...chartData.data);
  let ymax = Math.max(...chartData.data);

  // Ensure symmetry around zero
  if (Math.abs(ymin) > ymax) {
    ymax = Math.abs(ymin);
  } else {
    ymin = -ymax;
  }

  // Round ymin and ymax to the nearest 0.1
  ymin = Math.floor(ymin * 10) / 10;
  ymax = Math.ceil(ymax * 10) / 10;
  const stepSize = (ymax - ymin) / 8;
  const ctx = chart.context2d;
  // In order for the gradient to be drawn correctly, the chartArea must be sized correctly.
  // The first time the chart is drawn, the chartArea is not spaced correctly, but on the
  // second call it is.  By executing this twice the gradient is drawn correctly.
  for (let i = 0; i < 2; ++i) {
    const chartArea = chart.chart.chartArea;
    const gradient = generateGradient(ctx, chartArea);

    const inputData = {
      labels: chartData.times,
      datasets: [{
        label: chartData.timeLabel,
        data: chartData.data,
        backgroundColor: gradient,
        // define a pleasant gray that blends in with the background
        borderColor: 'rgba(16, 16, 16, 0.8)',
        fill: true
      }]
    };
    const options = createBiasGraphChartOptions(chartData, ymin, ymax, stepSize);
    if (i > 0) {
      options.onResize = (chartInstance) => {
        // console.log('Chart resized to: ', size);
        // For some reason timeout is needed or otherwise the chart will get some
        // bad values.
        setTimeout(() => {
          const currentChartArea = chartInstance.chartArea;
          const currentCtx = chartInstance.ctx;
          const currentGradient = generateGradient(currentCtx, currentChartArea);
          chartInstance.data.datasets[0].backgroundColor = currentGradient;
          chartInstance.update();
        }, 0);
      };
    }

    // merge
    chart.setAll(inputData, options);
    chart.update();
  }
  return chartData;
}

/**
 *
 */
function setRangeListener (domTarget, platformname, chart) {
  domTarget.forEach(dom => {
    const clickListeners = getClickListeners(dom);
    if (clickListeners) {
      console.error('Range listeners already set');
      return;
    }
    dom.addEventListener('click', async e => {
      // Change the active class and update the chart range
      const childNodes = e.currentTarget.parentNode.children;
      const targetClassList = e.currentTarget.classList;
      const range = e.currentTarget.innerText;
      const graphType = getGraphType(range);
      for (let i = 0; i < childNodes.length; i++) {
        const child = childNodes[i];
        child.classList.remove('activeRange'); // @TODO: Remove this when PureCSS is in play
        child.classList.remove('time-range-option-active');
      }
      targetClassList.add('activeRange'); // @TODO: Remove this when PureCSS is in play
      targetClassList.add('time-range-option-active');

      try {
        const data = await fetchBiasData(graphType, platformname);
        await updateData(chart, range, data);
      } catch (err) {
        console.warn("Data not available for this range.  It's probably too early.");
      }
    });
  });
}

export async function createBiasChart (options) {
  const { selectors, platformName } = options;

  if (!initializeDisplay(selectors.disabledOverlay, true)) {
    console.error(`Could not initialize ${selectors.disabledOverlay} as display: none;`);
    return false;
  }

  try {
    const $domCanvasDiv = document.querySelector(selectors.chart);
    $domCanvasDiv.outerHTML = '<canvas></canvas>';
  } catch (err) {
    console.error(`Could not find element with id ${selectors.chart}`);
    return false;
  }

  const chart = new QuickChart(selectors.canvas, 'line', options.chart_width, options.chart_height);
  chart.gradient_height = options.gradient_height;
  const domRange = document.querySelectorAll(selectors.range);
  setRangeListener(domRange, platformName, chart);
  const defaultGraphType = getGraphType(options.default_range);
  const data = await fetchBiasData(defaultGraphType, platformName);
  await updateData(chart, options.default_range, data);
  return data;
}
